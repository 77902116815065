import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const createTransaction = createAsyncThunk('createTransaction', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createTransactionAdd = createAsyncThunk('createTransactionAdd', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction/add',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getTransactionActive = createAsyncThunk('getTransactionActive', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction/active'
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlStart = createAsyncThunk('controlStart', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction/start',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlFinish = createAsyncThunk('controlFinish', async (id: number) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction/finish',
            params: { id: id }
        })
        return response
    } catch (error: any) {
        return error.response
    }
})