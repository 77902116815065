import { Divider, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../store";
import { getTransaction } from "../transaction.api";
import moment from "moment";
import moneyParser from "../../../shared/parseMoney";

export default function TransactionStruck(props: any) {

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const { transactionList } = useSelector((state: RootState) => state.transaction)

    useEffect(() => {
        dispatch<any>(getTransaction({
            id: searchParams.get("id")
        }))
            .then(() => {
                let i = setTimeout(() => {
                    window.print()
                    clearTimeout(i)
                }, 100)
            })
    }, [])

    return (
        <Fragment>
            <Typography variant="h4">
                Trans Sulawesi II
            </Typography>
            <Divider />
            {parseInt(searchParams.get("is_cashier") || "0") === 1
                ? (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>Nomor Transaksi</td>
                                <td>:</td>
                                <td>{transactionList[0]?.no}</td>
                            </tr>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{transactionList[0]?.name}</td>
                            </tr>
                            <tr>
                                <td><h2>Kamar</h2></td>
                                <td><h2>:</h2></td>
                                <td><h2>{transactionList[0]?.room_name}</h2></td>
                            </tr>
                            <tr>
                                <td>Waktu Menginap</td>
                                <td>:</td>
                                <td>{transactionList[0]?.days} Hari</td>
                            </tr>
                            {transactionList[0]?.days_list && JSON.parse(transactionList[0].days_list).map((days: any) => (
                                <tr key={days.id}>
                                    <td style={{ paddingLeft: "10px" }}>- tambahan {moment(days.ts_created).format("YYYY/MM/DD HH:mm:ss")}</td>
                                    <td>:</td>
                                    <td>{days.days} Hari</td>
                                </tr>
                            ))}
                            <tr>
                                <td>Total Harga</td>
                                <td>:</td>
                                <td>{moneyParser(transactionList[0]?.total_price)}</td>
                            </tr>
                        </tbody>
                    </table>
                )
                : (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>Nomor Transaksi</td>
                                <td>:</td>
                                <td>{transactionList[0]?.no}</td>
                            </tr>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{transactionList[0]?.name}</td>
                            </tr>
                            <tr>
                                <td>Telepon</td>
                                <td>:</td>
                                <td>{transactionList[0]?.phone}</td>
                            </tr>
                            <tr>
                                <td>Satpam</td>
                                <td>:</td>
                                <td>{transactionList[0]?.employee_a_name}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            }
        </Fragment>
    )
}