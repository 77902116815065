import { Box, Button, Card, CardContent, Container, MenuItem, TextField, useMediaQuery } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import TransactionSheet from "./TransactionSheet";

export default function Transaction() {

    const isMobile = useMediaQuery('(max-width:768px)')

    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [type, setType] = useState("ONE")

    useEffect(() => {
        let dt = new Date().getTime()
        setDateStart(moment(dt - 604800000).format('YYYY-MM-DD'))
        setDateEnd(moment(dt).format('YYYY-MM-DD'))
    }, [])

    return (
        <Container maxWidth='xl'>
            <Card>
                <CardContent>
                    <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={1}>
                        <TextField
                            fullWidth={isMobile}
                            margin="dense"
                            className="inp"
                            size="small"
                            variant="outlined"
                            type="date"
                            label="Awal"
                            value={dateStart}
                            onChange={(e) => setDateStart(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            fullWidth={isMobile}
                            margin="dense"
                            className="inp"
                            size="small"
                            variant="outlined"
                            type="date"
                            label="Akhir"
                            value={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            fullWidth={isMobile}
                            margin="dense"
                            size="small"
                            variant="outlined"
                            label="Tipe"
                            select
                            style={{ minWidth: 150 }}
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value="ONE">Per Transaksi</MenuItem>
                            <MenuItem value="DAY">Per Hari</MenuItem>
                            <MenuItem value="WEEK">Per Minggu</MenuItem>
                            <MenuItem value="MONTH">Per Bulan</MenuItem>
                            <MenuItem value="YEAR">Per Tahun</MenuItem>
                            <MenuItem value="ROOM">Per Kamar</MenuItem>
                        </TextField>
                        <a href={`/#/transaction/print?dateStart=${dateStart}&dateEnd=${dateEnd}&type=${type}`} target="_blank" style={{ textDecoration: "none" }}>
                            <Button variant="contained">
                                Print
                            </Button>
                        </a>
                    </Box>
                    <TransactionSheet dateStart={dateStart} dateEnd={dateEnd} type={type} />
                </CardContent>
            </Card>
        </Container>
    )
}