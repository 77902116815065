import { Box, Button, Grid } from "@mui/material";
import FormNumber from "../../../shared/FormNumber";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createTransactionAdd } from "../dashboard.api";

export default function DashboardBookedAdd(props: { transaction: any, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [days, setDays] = useState(0)

    const handleSubmit = () => {
        dispatch(createTransactionAdd({
            transaction_id: props.transaction.id,
            employee_id: JSON.parse(localStorage.getItem('bat-user') || "{}").id,
            days: days
        }))
            .unwrap()
            .then(() => {
                props.onSubmit()
            })
    }

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <FormNumber
                        label="Jumlah Hari"
                        value={days}
                        onChange={(e: any) => setDays(e.target.value)}
                    />
                </Grid>
                <Grid item md={12}>
                    <Button fullWidth variant="contained" onClick={handleSubmit}>Submit</Button>
                </Grid>
            </Grid>
        </Box>
    )
}