import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import loginReducer from './pages/login/login.reducer'
import containersReducer from './containers/containers.reducer'

import dashboardReducer from './pages/dashboard/dashboard.reducer'

import transactionReducer from './pages/transaction/transaction.reducer'

import roomReducer from './pages/master/room/room.reducer'
import roomTypeReducer from './pages/master/roomType/roomType.reducer'
import employeeReducer from './pages/master/employee/employee.reducer'
import roleReducer from './pages/master/role/role.reducer'

const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        login: loginReducer,
        containers: containersReducer,

        dashboard: dashboardReducer,

        transaction: transactionReducer,

        room: roomReducer,
        roomType: roomTypeReducer,
        employee: employeeReducer,
        role: roleReducer
    },
})

export type RootState = ReturnType<typeof store.getState>

export default store