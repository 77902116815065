import { MoreVert, Search } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, IconButton, InputAdornment, List, MenuItem, Pagination, Popover, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../containers/containers.reducer";
import { baseUrl } from "../state";
import checkAccess from "./checkAccess";
import notificationError from "./notificationError";
import CsvDownloader from 'react-csv-downloader';

export default function CustomTable(props: any) {

    const dispatch = useDispatch()

    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);
    const [columnHidden, setColumnHidden] = useState<any>([])
    const [paginationPage, setPaginationPage] = useState(1)
    const [paginationTake, setPaginationTake] = useState(5)
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [dataList, setDataList] = useState<any>([])
    const [idList, setIdList] = useState<any>([])
    const [dataRows, setDataRows] = useState(0)
    const [dataSelected, setDataSelected] = useState<any>({})
    const [lastTimeGetData, setLastTimeGetData] = useState<any>(undefined)
    const [dataIdSelected, setDataIdSelected] = useState<any>([])
    const [searchWord, setSearchWord] = useState("")

    useEffect(() => {
        let dt = new Date().getTime()
        setDateStart(moment(dt - 604800000).format('YYYY-MM-DD'))
        setDateEnd(moment(dt).format('YYYY-MM-DD'))
        setColumnHidden(props.columnHidden || [])
    }, [])

    useEffect(() => {
        if (props.timeGetData !== lastTimeGetData) {
            setLastTimeGetData(props.timeGetData)
            getData()
        }
        else if (dateStart && dateEnd) {
            getData()
        }
    }, [paginationPage, paginationTake, dateStart, dateEnd, props.timeGetData])

    useEffect(() => {
        setDataIdSelected([])
    }, [dateStart, dateEnd])

    const getData = () => {
        setDataSelected({})
        setPopAnchorAction(null)
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: `${baseUrl}${props.url}?search=${searchWord}${(props.additionalParams || '')}${props.filterDate ? `&datestart=${dateStart}&dateend=${dateEnd}` : ``}${props.paginations ? `&skip=${(paginationPage - 1) * paginationTake}&take=${paginationTake}` : ``}`,
        })
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                    setIdList(response.data.id_list || [])
                    setDataRows(response.data.rows)
                }
                else {
                    notificationError(response)
                }
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }

    const handleChangeShow = (e: any) => {
        setPaginationTake(parseInt(e.target.value))
        setPaginationPage(1)
    }

    const handleChangeDataSelected = (e: any) => {
        const { name, value } = e.target
        setDataSelected({
            ...dataSelected,
            [name]: value
        })
    }

    const handleCheck = (id: string) => {
        if (dataIdSelected.includes(id)) {
            setDataIdSelected(dataIdSelected.filter((item: string) => item !== id))
        }
        else {
            setDataIdSelected([...dataIdSelected, id])
        }
    }

    const handleCheckAll = () => {
        if (dataIdSelected.length === dataList.length) {
            setDataIdSelected([])
        }
        else {
            setDataIdSelected(idList)
        }
    }

    const handleChangeSearch = (e: any) => {
        let { value } = e.target
        setSearchWord(value)
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getData()
    }

    const handleExport = async () => {
        if (props.exportUrl) {
            let result: any = await new Promise((resolve) => {
                setLoading(true)
                axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
                    },
                    url: `${baseUrl}${props.exportUrl}?ids=${dataIdSelected.map((d: any) => { return `'${d}'` })}`,
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            resolve(response.data.data)
                        }
                        else {
                            notificationError(response)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            return result
        }
        else {
            let result: any = await new Promise((resolve) => {
                setLoading(true)
                axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
                    },
                    url: `${baseUrl}${props.url}?datestart=${dateStart}&dateend=${dateEnd}`,
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            resolve(response.data.data)
                        }
                        else {
                            notificationError(response)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            return result
        }
    }

    return (
        <Fragment>
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    {props.rowActions && <props.rowActions data={dataSelected} />}
                </List>
            </Popover>
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            {props.filterDate && (
                                <Fragment>
                                    <TextField
                                        className="inp"
                                        size="small"
                                        variant="outlined"
                                        type="date"
                                        label="Awal"
                                        value={dateStart}
                                        onChange={(e) => setDateStart(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        className="inp"
                                        size="small"
                                        variant="outlined"
                                        type="date"
                                        label="Akhir"
                                        value={dateEnd}
                                        onChange={(e) => setDateEnd(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Fragment>
                            )}
                            <form onSubmit={handleSearch}>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                    }}
                                    placeholder="Ketik lalu enter untuk mencari"
                                />
                                <button type="submit" hidden={true} />
                            </form>
                        </div>
                        <div>
                            {props.export &&
                                <CsvDownloader
                                    columns={props.columns.map((c: any) => { return { displayName: c.name, id: c.key } })}
                                    datas={handleExport}
                                    filename={`exported_data_${moment(dateStart).format('DDMMYYYY')}-${moment(dateEnd).format('DDMMYYYY')}`}
                                >
                                    <Button
                                        variant="contained"
                                    >
                                        Export ke Excel
                                    </Button>
                                </CsvDownloader>
                            }
                            {props.headerActions && <props.headerActions dataIdSelected={dataIdSelected} dateStart={dateStart} dateEnd={dateEnd} />}
                        </div>
                    </div>
                    <Divider />
                    <div className="body">
                        <table>
                            <thead>
                                <tr>
                                    {props.selectRow && (
                                        <td>
                                            <Checkbox
                                                checked={dataIdSelected.length === idList.length}
                                                onChange={handleCheckAll}
                                            />
                                        </td>
                                    )}
                                    {props.columns.map((column: any, i: number) => (
                                        !columnHidden.includes(column.name) && <td key={i}>{column.name}</td>
                                    ))}
                                    {((props.rowActions && (checkAccess("EDIT") || checkAccess("DELETE"))) || props.rowActionsAdditional) && (
                                        <td>
                                            Aksi
                                        </td>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data: any, i: number) => (
                                    <tr key={i} className={props.onClickRow && "clickable"} onClick={() => props.onClickRow && props.onClickRow(data)}>
                                        {props.selectRow && (
                                            <td>
                                                <Checkbox
                                                    checked={dataIdSelected.includes(data.id)}
                                                    onChange={() => handleCheck(data.id)}
                                                />
                                            </td>
                                        )}
                                        {props.columns.map((column: any, j: number) => (
                                            !columnHidden.includes(column.name) && (
                                                dataSelected.id === data.id && column.form
                                                    ? (
                                                        <td key={j}>
                                                            <TextField
                                                                key={j}
                                                                style={{ minWidth: 100 }}
                                                                size="small"
                                                                type={column.form.type}
                                                                label={column.name}
                                                                name={column.key}
                                                                value={dataSelected[column.key] || ""}
                                                                onChange={handleChangeDataSelected}
                                                            />
                                                        </td>
                                                    )
                                                    : (
                                                        column.customRender
                                                            ? (
                                                                <column.customRender key={j} data={data} />
                                                            )
                                                            : (
                                                                <td key={j}>{data[column.key]}</td>
                                                            )
                                                    )
                                            )
                                        ))}
                                        {((props.rowActions && (checkAccess("EDIT") || checkAccess("DELETE"))) || props.rowActionsAdditional) && (
                                            <td>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {props.rowActions && (
                                                        <IconButton onClick={e => { setPopAnchorAction(e.currentTarget); setDataSelected(data) }}>
                                                            <MoreVert />
                                                        </IconButton>
                                                    )}
                                                    {props.rowActionsAdditional && <props.rowActionsAdditional data={data} />}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
                {props.paginations && (
                    <CardActions className="custom-table-footer">
                        <div className="rows-per-page">
                            <Typography color={"text.disabled"}>
                                Menampilkan {paginationTake > dataRows ? dataRows : paginationTake} dari {dataRows} data
                            </Typography>
                            <FormControl fullWidth size="small">
                                <TextField size="small" select className="options" value={paginationTake} onChange={handleChangeShow}>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </TextField>
                            </FormControl>
                        </div>
                        <Pagination sx={{ marginLeft: "auto" }} count={Math.ceil(dataRows / paginationTake)} color="primary" page={paginationPage} onChange={(e, value) => setPaginationPage(value)} />
                    </CardActions>
                )}
            </Card>
        </Fragment >
    )
}