import { ChevronRight, ExpandLess } from "@mui/icons-material"
import { Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routeList from "../../routeList"

export default function Sidetab() {

    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:768px)')

    const [expanded, setExpanded] = useState("")
    const [accessList, setAcceesList] = useState<any>([])
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        let dataString = localStorage.getItem('bat-user')
        let data = dataString ? JSON.parse(dataString) : {}
        let access = data.role_access ? JSON.parse(data.role_access) : {}
        setAcceesList(access)
    }, [])

    const handleClickMenu = (menu: any, child: any = null, e: any = null) => {
        if (Boolean(child)) {
            navigate(menu.path + child.path)
        }
        else {
            if (!menu.child) {
                navigate(menu.path)
            }
            else {
                if (isMobile) {
                    if (Boolean(anchorEl)) {
                        setAnchorEl(null)
                    }
                    else {
                        setAnchorEl(e.currentTarget)
                    }
                }
                else {
                    if (expanded === menu.path) {
                        setExpanded("")
                    }
                    else {
                        setExpanded(menu.path)
                    }
                }
            }
        }
    }

    if (isMobile) {
        return <Drawer
            open={true}
            variant="permanent"
        >
            <div className="sidetab">
                <div className="brand">
                </div>
                <List>
                    {routeList
                        .filter((m: any) =>
                            m.child
                                ? m.child.some((c: any) => Object.keys(accessList).includes(m.path + c.path))
                                : Object.keys(accessList).includes(m.path)
                        )
                        .map((menu: any, i) => (
                            <div key={i}>
                                <ListItemButton className={(
                                    window.location.hash.includes(menu.path)
                                        ? " active"
                                        : ""
                                )} onClick={(e) => handleClickMenu(menu, null, e)}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                </ListItemButton>
                                {Boolean(menu.child) && (
                                    <Popover
                                        id={menu.path}
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <List disablePadding>
                                            {menu.child.filter((c: any) => Object.keys(accessList).includes(menu.path + c.path)).map((child: any, i: number) => (
                                                <ListItemButton key={i} onClick={() => handleClickMenu(menu, child)}>
                                                    <ListItemText primary={child.name} />
                                                    <ChevronRight />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Popover>
                                )}
                            </div>
                        ))}
                </List>
            </div>
        </Drawer>
    }

    return (
        <Drawer
            open={true}
            variant="permanent"
        >
            <div className="sidetab">
                <div className="brand">
                    <Typography variant="h4">
                        Trans Sulawesi II
                    </Typography>
                </div>
                <List>
                    {routeList
                        .filter((m: any) =>
                            m.child
                                ? m.child.some((c: any) => Object.keys(accessList).includes(m.path + c.path))
                                : Object.keys(accessList).includes(m.path)
                        )
                        .map((menu: any, i) => (
                            <div key={i}>
                                <ListItemButton className={(
                                    window.location.hash.includes(menu.path)
                                        ? " active"
                                        : ""
                                )} onClick={() => handleClickMenu(menu)}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                    {expanded === menu.path ? <ExpandLess /> : <ChevronRight />}
                                </ListItemButton>
                                {menu.child && (
                                    <Collapse in={expanded === menu.path}>
                                        <List disablePadding>
                                            {menu.child.filter((c: any) => Object.keys(accessList).includes(menu.path + c.path)).map((child: any, i: number) => (
                                                <ListItemButton key={i} onClick={() => handleClickMenu(menu, child)}>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary={child.name} />
                                                    <ChevronRight />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                </List>
            </div>
            <div style={{ display: "flex", alignItems: "flex-end", flex: 1, justifyContent: "center", padding: 10 }}>
                <Typography variant="h6">
                    JTM Group 2023
                </Typography>
            </div>
        </Drawer>
    )
}