import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createRoomType = createAsyncThunk('createRoomType', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room/type',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteRoomType = createAsyncThunk('deleteRoomType', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room/type?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editRoomType = createAsyncThunk('editRoomType', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room/type',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})