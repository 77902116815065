import { createSlice } from '@reduxjs/toolkit'
import { createRoomType, deleteRoomType, editRoomType } from './roomType.api'

export interface RoomTypeState {
    loading: boolean
}
const initialState: RoomTypeState = {
    loading: false
}

export const roomTypeSlice = createSlice({
    name: 'roomType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createRoomType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRoomType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRoomType.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRoomType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRoomType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRoomType.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editRoomType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRoomType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRoomType.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default roomTypeSlice.reducer