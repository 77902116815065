import { Grid, TextField } from "@mui/material";
import moneyParser from "./parseMoney";

export default function FormMoney(props: any) {

    const handleChange = (value: string) => {
        props.onChange({
            target: {
                name: props.name,
                value: value.replaceAll('.', '')
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={3} className="label-wrap">
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder={props.label}
                    name={props.name}
                    value={moneyParser(props.value)}
                    onChange={(e) => handleChange(e.target.value)}
                    required={props.required}
                    disabled={props.disabled}
                    autoFocus
                />
            </Grid>
        </Grid>
    )
}