import { AddHome, Print } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import notificationError from "../../../shared/notificationError";
import { RootState } from "../../../store";
import { controlFinish, controlStart, getTransactionActive } from "../dashboard.api";
import FormAutocompleteObject from "../../../shared/FormAutocompleteObject";
import FormNumber from "../../../shared/FormNumber";
import moment from "moment";
import DashboardBookedAdd from "./DashboardBookedAdd";
import moneyParser from "../../../shared/parseMoney";
import CustomTable from "../../../shared/CustomTable";

export default function DashboardBooked() {

    const { activeTransactionList } = useSelector((state: RootState) => state.dashboard)

    const [modalCheckIn, setModalCheckin] = useState(false)
    const [modalAddDay, setModalAddDay] = useState(false)
    const [transactionSelected, setTransactionSelected] = useState<any>({})
    const [roomSelected, setRoomSelected] = useState<any>({})
    const [days, setDays] = useState(1)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch<any>(getTransactionActive())
        let j = setInterval(() => {
            dispatch<any>(getTransactionActive())
        }, 10000)
        return () => {
            clearInterval(j)
        };
    }, [])

    const handleControlStart = (transaction: any) => {
        setTransactionSelected(transaction)
        setModalCheckin(true)
    }

    const handleAddDay = (transaction: any) => {
        setTransactionSelected(transaction)
        setModalAddDay(true)
    }

    const handleAddDaySubmit = () => {
        setModalAddDay(false)
        dispatch<any>(getTransactionActive())
    }

    const handleSubmitRoom = () => {
        dispatch<any>(controlStart({
            id: transactionSelected.id,
            room_id: roomSelected.id,
            price: roomSelected.type_price,
            days: days,
            employee_b_id: JSON.parse(localStorage.getItem('bat-user') || "{}").id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch<any>(getTransactionActive())
                    window.open(`/#/transaction/struck?id=${transactionSelected.id}&is_cashier=1`, '__blank')
                    setModalCheckin(false)
                    setTransactionSelected({})
                    setRoomSelected({})
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleControlFinish = (id: number) => {
        Swal.fire({
            title: "Selesaikan transaksi",
            text: "tindakan ini tidak bisa dibatalkan",
            icon: "question",
            showCancelButton: true
        })
            .then((response) => {
                if (response.isConfirmed) {
                    dispatch<any>(controlFinish(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                dispatch<any>(getTransactionActive())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Grid container spacing={2}>
            <CustomModal
                open={modalCheckIn}
                onClose={() => setModalCheckin(false)}
                title="Pilih Kamar"
                component={
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Nomor</TableCell>
                                                <TableCell>:</TableCell>
                                                <TableCell>{transactionSelected.no}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Nama</TableCell>
                                                <TableCell>:</TableCell>
                                                <TableCell>{transactionSelected.name}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item md={12}>
                                    <FormNumber
                                        label="Hari"
                                        value={days}
                                        onChange={(e: any) => setDays(e.target.value)}
                                    />
                                    <br />
                                    <CustomTable
                                        url={"/room"}
                                        paginations={true}
                                        columns={[
                                            {
                                                name: "Nama",
                                                key: "name"
                                            },
                                            {
                                                name: "Tipe",
                                                key: "type_name"
                                            },
                                            {
                                                name: "Harga",
                                                key: "type_price",
                                                customRender: (props: any) => (
                                                    <td>
                                                        {moneyParser(props.data.type_price)}
                                                    </td>
                                                )
                                            }
                                        ]}
                                        rowActionsAdditional={(props: any) => (
                                            <Fragment>
                                                {roomSelected.id === props.data.id
                                                    ? (
                                                        <Button
                                                            variant="contained"
                                                            disabled
                                                        >
                                                            Dipilih
                                                        </Button>
                                                    )
                                                    : (
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => setRoomSelected(props.data)}
                                                        >
                                                            Pilih
                                                        </Button>
                                                    )
                                                }
                                            </Fragment>
                                        )}
                                    />
                                </Grid>
                                <Grid item md={12} style={{ textAlign: "right" }}>
                                    <Button variant="contained" onClick={handleSubmitRoom}>Submit</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }
            />
            <CustomModal
                open={modalAddDay}
                onClose={() => setModalAddDay(false)}
                title="Tambah hari"
                component={<DashboardBookedAdd transaction={transactionSelected} onSubmit={handleAddDaySubmit} />}
            />
            {activeTransactionList.map((transaction: any) => (
                <Grid item lg={3} md={4} xs={12} key={transaction.id}>
                    <Card>
                        <CardContent className="timer">
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Nomor</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.no}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Nama</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Telepon</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Kamar</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.room_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Satpam</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.employee_a_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Kasir</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{transaction.employee_b_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Harga</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moneyParser(transaction.total_price)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Check In</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moment(transaction.ts_start).format("YYYY/MM/DD HH:mm:ss")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Check Out</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moment(transaction.ts_start).add(transaction.total_days, 'days').format("YYYY/MM/DD 12:00:00")}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    {transaction.ts_start === null
                                        ? checkAccess("TIMER_CONTROL_START") && (
                                            <Button variant="contained" className="start" onClick={() => handleControlStart(transaction)}>Check In</Button>
                                        )
                                        : checkAccess("TIMER_CONTROL_STOP") && (
                                            <Button variant="contained" className="finish" onClick={() => handleControlFinish(transaction.id)}>Check Out</Button>
                                        )
                                    }
                                </Grid>
                                <Grid item md={3}>
                                    {checkAccess("BOOKING") && (
                                        <Tooltip title="Tambah hari">
                                            <IconButton onClick={() => handleAddDay(transaction)}>
                                                <AddHome />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item md={3}>
                                    <IconButton onClick={() => window.open(`/#/transaction/struck?id=${transaction.id}&is_cashier=1`, '__blank')}>
                                        <Print />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}